.iconItemsContainer {
  flex-wrap: nowrap;
  gap: 18px;
  text-align: left;
  max-width: 327px;
  justify-content: center;
}

.iconItem {
  align-items: center;
  display: flex;
  flex: 1;
  gap: 8px;
  max-width: fit-content;
  justify-content: center;
}

.icon {
  flex-shrink: 0;
  height: 24px;
  width: 24px;
}

.iconText {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 12px;
  width: min-content;
}

@media (--viewport-md) {
  .iconItemsContainer {
    max-width: none;
    justify-content: space-between;
  }

  .iconText {
    font-size: 18px;
    letter-spacing: 0.5px;
    line-height: 22px;
    width: 140px;
  }

  .icon {
    height: 32px;
    width: 32px;
  }
}

@media (--viewport-lg) {
  .iconItemsContainer {
    display: none;
  }
}
