.wrapper {
  display: none;
}

.container {
  height: 86px;
  margin: auto;
  background-color: #f0f3f0;
}

.itemsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  max-width: 1500px;
  height: 100%;
}

.itemsWrapperWithRoom {
  justify-content: flex-end;
}

.itemsContainer {
  height: 100%;
  width: 1440px;
  max-width: 1045px;
  display: flex;
  gap: 34px;
  justify-content: space-between;
  align-items: center;
  padding-right: var(--tfd-spacing-md);
  padding-left: var(--tfd-spacing-md);
}

.item {
  display: flex;
  align-items: center;
  gap: var(--tfd-spacing-xs);
}

.icon {
  height: 36px;
}

@media (--viewport-lg) {
  .wrapper {
    display: block;
  }

  .itemsContainer {
    gap: var(--tfd-spacing-sm);
    padding-right: var(--tfd-spacing-sm);
    padding-left: var(--tfd-spacing-sm);
  }
}

@media (--viewport-xl) {
  .wrapper {
    padding: 0;
  }
  .itemsContainer {
    padding-right: var(--tfd-spacing-md);
    padding-left: var(--tfd-spacing-md);
  }
  .itemsContainerWithRoom {
    max-width: 70%;
  }
}
