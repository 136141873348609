.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 60px;
  justify-content: center;
  overflow-x: clip;
  padding-top: var(--margin-md);
}

@media (--viewport-lg) {
  .container {
    gap: var(--margin-4xl);
    padding-top: var(--margin-xxl);
  }
}
