.container {
  margin: auto;
  overflow: hidden;
  position: relative;
}

.picture {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.cta {
  bottom: var(--tfd-spacing-xl);
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.overlay {
  display: block;
  position: relative;
  z-index: 10;
}

.container::after {
  display: block;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    172.77deg,
    rgba(0, 0, 0, 0) 61.72%,
    rgba(0, 0, 0, 0.7) 82.97%
  );
}

@media (--viewport-md) {
  .cta {
    display: none !important;
  }

  .wrapper {
    margin-bottom: var(--tfd-spacing-lg);
  }

  .container {
    height: 470px;
  }
}

@media (--viewport-lg) {
  .container::after {
    display: none;
  }
}

@media (--viewport-xl) {
  .container {
    width: 100vw;
    height: 54vh;
    max-height: 500px;
  }

  .overlay {
    display: none;
  }
}
