.image {
  max-width: 75px;
  max-height: unset;
}

@media (--viewport-md) {
  .wrapper {
    margin-top: var(--margin-lg);
  }
}

@media (--viewport-lg) {
  .image {
    max-width: 110px;
  }
}
