.divider {
  padding-bottom: var(--margin-sm);
}

.buttonContainer {
  margin-top: 10px;
}

.multiPetButton {
  width: 241px;
}

.multiPetNumInput {
  width: 200px;
}

.checkboxContainer {
  margin: var(--margin-sm) 0;
}

.checkbox {
  margin: var(--margin-xs) 0 0;
}

.copyButton {
  width: 241px;
}
