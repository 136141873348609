.card {
  cursor: pointer;
  width: 100%;
  height: 84px;
  border: 1px solid var(--charcoal-0);
  padding: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.radioInput {
  /* We hide the radio input to avoid a "black box" appearing when the card is selected */
  /* Ticket: https://app.shortcut.com/farmersdog/story/135194/uat-for-card-experiment-w-continue-button-round-2 */
  opacity: 0;
  position: absolute;
  appearance: none;
  pointer-events: none;
}

.card.selected {
  border: 2px solid var(--kale-3);
  /* remove 1px padding while selected so image and text don't shift inward when border grows */
  padding: 0px;
}

.image {
  max-height: 66px;
}

.heading {
  text-transform: capitalize;
}

.heading,
.description {
  margin: 0;
}

.label {
  line-height: 32px;
  color: var(--kale-3);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (--viewport-sm) {
  .description {
    text-align: left;
    padding-right: var(--tfd-spacing-md);
  }
  .image {
    margin-top: var(--margin-xs);
  }
}

@media (--viewport-lg) {
  .card:hover {
    border: 1px solid var(--kale-3);
  }
  .card.selected {
    border: 2px solid var(--kale-3);
    /* remove 1px padding while selected so image and text don't shift inward when border grows */
    padding: 0px;
  }

  .card {
    height: 300px;
  }

  .image {
    margin-top: var(--margin-lg);
    margin-bottom: 40px;
    max-height: 112px;
  }
  .description {
    padding-right: var(--tfd-spacing-md);
    padding-left: var(--tfd-spacing-md);
    padding-bottom: var(--tfd-spacing-md);
    text-align: center;
  }
}
