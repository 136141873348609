.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;
  overflow-x: visible;
}

.mobile {
  position: relative;
  text-align: center;
}

.desktop {
  margin: auto;
  max-width: 1440px;
  position: relative;
  text-align: center;
}
